import Layout from '../layout';
import React, { FC } from 'react';
import { Page } from '../layout/header';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
  location: Location;
}

// Problem solver
// Interests: architecture, team leading, building products (UI/UX/product design, everything else that belongs to that)
// Working on side projects, continually educating myself
// Values

const AboutMe: FC<Props> = ({ location }) => (
  <Layout currentPage={Page.AboutMe} location={location}>
    <main>
      <StaticImage
        src="../../media/images/dominik-weber-circle.png"
        alt="Website Owner Image"
        width={320}
        style={{
          maxWidth: '50%',
          width: '20em',
          shapeOutside: 'circle(50%)',
          shapeMargin: '0.5em',
          float: 'right'
        }}
      />
      <h1>Dominik Weber</h1>
      <p>
        I'm a software developer with a particular interest in software architecture. Having multiple pieces of software
        working together to generate value for the customer fascinates me.
      </p>
      <p>I see the discipline of software development as the art of solving problems with code.</p>
      <p>
        Next to my day job I also like to work on side-projects and educate myself in topics necessary to build great
        products, specifically product and UI/UX design.
      </p>
      <p>Continuous learning in general is something I highly value and devote a big chunk of my spare time to.</p>

      <h2>Values</h2>
      <p>
        Values are an important part of how work gets done. Mine are
        <ul>
          <li>Quality and efficiency</li>
          <li>Wholistic thinking</li>
          <li>Ownership and autonomy</li>
          <li>Trust and respect</li>
        </ul>
      </p>
      <p>
        I will strive towards them, to uphold them and try to change the environment if necessary. You can read more
        about them in my blogpost fittingly called <a href="/blogposts/values">Values</a>.
      </p>

      <h2>Resume</h2>
      <p>
        You can find my resume <a href="/cv.pdf">here</a>.
      </p>
      <h2>Contact</h2>
      <p>
        You can find me on <a href="https://twitter.com/Domysee">Twitter</a> or shoot me an email to{' '}
        <a href="mailto:contact@dominik-weber.name">contact@dominik-weber.name</a>
      </p>
    </main>
  </Layout>
);

export default AboutMe;
